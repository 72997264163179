import skcircle from "../images/skcircle.png";

export default function Name() {
    return (

        <div className="logo">



            {/* <a href="/"><img src={skcircle} className="skcircle" /></a> */}
            {/* <p className="k">K</p> */}
            <a href="/" className="nameLink"><h1 className="name">Stephanie Korch</h1></a>




        </div>

    )
}

